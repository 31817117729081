import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Head from 'components/head';
import SectionMain from 'components/section-main';
import Title from 'components/title';

const Regolamento = ({ data }) => {
  const PAGE = data.regolamentoJson;
  const REGOLAMENTO = PAGE.sections[0];

  return (
    <>
      <Head pageTitle={PAGE.title} />

      <SectionMain theme={{ gridColumns: '1fr' }}>
        <Title as="h1" color="blue">
          {REGOLAMENTO.title}
        </Title>

        <div
          className="body"
          dangerouslySetInnerHTML={{
            __html: REGOLAMENTO.content.childMarkdownRemark.html,
          }}
        />
      </SectionMain>
    </>
  );
};

Regolamento.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Regolamento;

export const query = graphql`
  query RegolamentoQuery {
    regolamentoJson {
      title
      sections {
        title
        content {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
      }
    }
  }
`;
